import * as React from "react"


const HomeAndInteriors = () => (
  <>

  </>
)

export default HomeAndInteriors
